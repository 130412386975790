import DocumentationPage from "@components/DocumentationPage";
import ProfilePicture from "@components/web/users/ProfilePicture";

export default () => <DocumentationPage>
    <h1>Standard picture with blurhash</h1>
    <ProfilePicture 
        size={200} 
        images={[{
            url: "https://en.gravatar.com/userimage/145933994/6279ec15a662e93177d44770cfd616dc.jpg?size=1000",
            width: 500,
            height: 500,
            blurHash: "LnIhNvVYbapIyZR%s.flb{f+j?s."
        }]} />

    <h1>Picture not found</h1>
    <ProfilePicture 
        size={200} 
        images={[{
            url: "https://foobar",
            width: 500,
            height: 500,
            blurHash: "LnIhNvVYbapIyZR%s.flb{f+j?s."
        }]} />

    <h1>Picture with empty images array</h1>
    <ProfilePicture 
        size={200} 
        images={[]} />
</DocumentationPage>